import React, { useEffect } from "react";

import Cookies from "js-cookie";

import { Page, Layout, Card, Banner } from "@shopify/polaris";

export default function Home() {
  useEffect(() => {
    let cookies = Cookies.get();
    console.log("Cookies!");
    console.log(cookies);
  }, []);


  return (
    <Page title="PLEXSYS Marketing Manager App" separator>
      <Layout>
        <Layout.Section oneHalf>
          <Card
            title="PDF Version Control"
            sectioned
            primaryFooterAction={{ content: "Manage", url: "/PDf-Versioning" }}
          >
            <p>Manage Product Brochures and Manuals</p>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card
            title="Event Tracking"
            sectioned
            primaryFooterAction={{ content: "Manage", url: "/events" }}
          >
            <p>Manage Events and Related QR Codes</p>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card
            title="QR Codes"
            sectioned
            primaryFooterAction={{ content: "Manage", url: "/qr-codes" }}
          >
            <p>Manage QR Codes for Reused Marketing Material and Employees</p>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card
            title="Contact Forms"
            sectioned
            primaryFooterAction={{ content: "Manage", url: "/contact-form" }}
          >
            <p>Manage Contact Forms</p>
          </Card>
        </Layout.Section>

      </Layout>
    </Page>
  );
}
