import React, { createContext, useReducer } from "react";
import { reducer, globalState, types } from "./reducers";

import axios from "axios";

const StoreContext = createContext(globalState);

const StoreProvider = ({ children }) => {
  // Set up reducer with useReducer and our defined reducer, globalState from reducers.js
  const [state, dispatch] = useReducer(reducer, globalState);

  async function makeRequest(method, route, data) {
    return new Promise((resolve, reject) => {
      axios({
        method,
        url: "/api" + route,
        headers: { Authorization: "Bearer " + state.apiAuth.token },
        data,
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            reject(err.response.data);
          } else if (err.message === "Network Error") {
            reject("Server Unresponsive");
          } else {
            reject("Server Took Too Long To Respond");
          }
        });
    });
  }

  function setUser(user) {
    dispatch({
      type: types.SET_API,
      payload: user,
    });
  }

  function signOut() {
    dispatch({
      type: types.SET_API,
      payload: {},
    });
  }

  return (
    <StoreContext.Provider
      value={{
        state,
        dispatch,
        makeRequest,
        signOut,
        setUser,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
