import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { StoreContext } from "../context/StoreContext";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Button,
  Form,
  FormLayout,
} from "@shopify/polaris";

import List from "../components/List";
import ModalContainer from "../components/ModalContainer";

export default function PDFHome() {
  const history = useHistory();
  const { makeRequest } = useContext(StoreContext);

  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");

  const [products, setProducts] = useState([
    {
      id: 100,
      url: "/PDf-Versioning/341",
      heading: "ASCOT 5",
    },
    {
      id: 200,
      url: "/PDf-Versioning/256",
      heading: "ASCOT 7",
    },
    {
      id: 200,
      url: "/PDf-Versioning/256",
      heading: "sonomarc",
    },
  ]);

  const [file, setFile] = useState();

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", "testUpload");

    makeRequest("POST", "/PDF/upload", formData).then(({ data }) => {
      console.log(data);
      history.go(0);
    });
  };

  const toggleModal = (state) => {
    if (typeof state == "undefined") {
      setModal(!modal);
    } else {
      setModal(state);
    }
  };

  const addNewProduct = () => {
    console.log("Adding");
    console.log(name);
    makeRequest("POST", "/Product", {
      name,
      handle,
    }).then(({ data }) => {
      history.go(0);
    });
  };

  useEffect(() => {
    if (file) {
      console.log(file);
      uploadFile();
    }
  }, [file]);

  useEffect(() => {
    makeRequest("GET", "/Product").then(({ data }) => {
      let productArray = data.map((x) => {
        let { name, _id } = x;
        return { id: _id, heading: name, url: `/PDF-Versioning/${_id}` };
      });
      setProducts(productArray);
    });
    if (file) {
      console.log(file);
      uploadFile();
    }
  }, []);

  return (
    <Page
      title="PLEXSYS PDF Version Control"
      separator
      primaryAction={{
        content: "Add Product",
        onAction: () => {
          toggleModal();
        },
      }}
    >
      <Layout>
        <Layout.Section secondary>
          <Heading>Products</Heading>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <List items={products} />
          </Card>
        </Layout.Section>
      </Layout>
      <ModalContainer
        title={"Add New Product"}
        open={modal}
        close={() => {
          toggleModal(false);
        }}
      >
        <Form onSubmit={addNewProduct}>
          <FormLayout>
            <TextField label="Product Name" value={name} onChange={setName} />
            <TextField
              label="Product URL Handle"
              value={handle}
              onChange={setHandle}
            />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
    </Page>
  );
}
