import React from "react";
import { Modal } from "@shopify/polaris";

export default function ModalContainer({
  children,
  open,
  close,
  title,
  primaryAction,
  secondaryActions,
}) {
  return (
    <Modal
      title={title}
      open={open}
      onClose={close}
      primaryAction={primaryAction ? primaryAction : null}
      secondaryActions={secondaryActions ? secondaryActions : null}
    >
      <Modal.Section>{children}</Modal.Section>
    </Modal>
  );
}
