import React from "react";
import {
  Avatar,
  TextStyle,
  ResourceItem,
  ResourceList,
} from "@shopify/polaris";

export default function List({
  items,
  singular,
  plural = singular + "s",
  action,
}) {
  return (
    <ResourceList
      resourceName={{ singular, plural }}
      items={items}
      renderItem={(item, _, index) => {
        const { id, url, heading, subheading, shortcutActions } = item;
        console.log();
        return (
          <ResourceItem
            key={index}
            id={id}
            url={url}
            accessibilityLabel={`View details for ${heading}`}
            onClick={() => {
              if (action) {
                action(item, index);
              }
            }}
            shortcutActions={shortcutActions}
          >
            <h3>
              <TextStyle variation="strong">{heading}</TextStyle>
            </h3>
            {subheading && <div>{subheading}</div>}
          </ResourceItem>
        );
      }}
    />
  );
}
