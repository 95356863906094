import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../context/StoreContext";

import {
  Page,
  Layout,
  Card,
  Banner,
  Form,
  FormLayout,
  TextField,
  Button,
} from "@shopify/polaris";

export default function Login() {
  const history = useHistory();

  const { makeRequest, setUser } = useContext(StoreContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitLogin = (e) => {
    makeRequest("POST", "/Auth/login", { email, password })
      .then((res) => {
        console.log(res.data);
        setUser(res.data);
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
        alert("Wrong Email or Password");
      });
  };

  return (
    <Page title="PLEXSYS Marketing Manager App" separator>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Form onSubmit={submitLogin}>
              <FormLayout>
                <TextField
                  value={email}
                  onChange={setEmail}
                  label="Email"
                  type="text"
                />
                <TextField
                  value={password}
                  onChange={setPassword}
                  label="Password"
                  type="password"
                />

                <Button submit>Submit</Button>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
