import React, { useState, useEffect, useMemo, useContext } from "react";

import { useHistory } from "react-router-dom";

import { StoreContext } from "../../context/StoreContext";

import { Bar } from "react-chartjs-2";

import { QRCode } from "react-qrcode-logo";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Form,
  FormLayout,
} from "@shopify/polaris";

import andriod from "../../assets/andriod.png";
import andriodjpg from "../../assets/andriod-jpg.jpg";
import andriodpng from "../../assets/andriod-png.png";

import List from "../../components/List";
import ModalContainer from "../../components/ModalContainer";

export default function Events_View(props) {
  const history = useHistory();
  const [qr, setQR] = useState({});

  const { makeRequest } = useContext(StoreContext);

  const [name, setName] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [newData, setNewData] = useState({});

  const [qrCodes, setQRCodes] = useState([]);

  const bardata = useMemo(() => {
    let data = qrCodes.map((code) => {
      return code.visits.length;
    });

    let labels = qrCodes.map((code) => {
      return code.heading;
    });

    let result = {
      labels,
      datasets: [
        {
          label: "Visits",
          data,
          borderWidth: 1,
        },
      ],
    };

    return result;
  }, [qrCodes]);

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const viewQRCode = (e, i) => {
    setQR(qrCodes[i]);
    toggleModal("view");
  };

  const deleteEvent = (e, i) => {
    console.log("Deleting");
    makeRequest("DELETE", "/event/single/" + props.match.params.id)
      .then(({ data }) => {
        history.push("/Events");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createQRCode = () => {
    let payload = { event: props.match.params.id, qrType: "event", ...newData };
    makeRequest("POST", "/QR", payload)
      .then(({ data }) => {
        history.go(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (field) => {
    return (value) => {
      setNewData({ ...newData, [field]: value });
    };
  };

  const toggleModal = (modal) => {
    switch (modal) {
      case "view":
        setViewModal(!viewModal);
        break;
      case "create":
        setCreateModal(!createModal);
        break;
      case "delete":
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    makeRequest("GET", "/QR/event/" + props.match.params.id).then(
      ({ data }) => {
        console.log(data);
        let newData = data.map((code) => {
          code.heading = code.name;
          code.subheading = `${code.url} | Visits: ${code.visits.length}`;
          code.qr = `qr.plexsys.com/visit/${code._id}`;
          delete code.url;
          return code;
        });

        setQRCodes(newData);
      }
    );
    makeRequest("GET", "/event/single/" + props.match.params.id).then(
      ({ data }) => {
        setName(data.name);
      }
    );
  }, []);

  return (
    <Page
      title={name}
      separator
      primaryAction={{
        content: "Add QR Code",
        onAction: () => {
          toggleModal("create");
        },
      }}
      secondaryActions={[
        {
          content: "Delete Event",
          onAction: () => toggleModal("delete"),
        },
      ]}
    >
      <Layout>
        {qrCodes.length > 0 ? (
          <React.Fragment>
            <Layout.Section fullWidth>
              <Bar data={bardata} options={options} />
            </Layout.Section>
            <Layout.Section secondary>
              <Heading>QR Codes</Heading>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <List items={qrCodes} action={viewQRCode} />
              </Card>
            </Layout.Section>
          </React.Fragment>
        ) : (
          <Layout.Section fullWidth>
            <Card sectioned>
              <Heading>Add QR Code to Start Tracking Event</Heading>
            </Card>
          </Layout.Section>
        )}
      </Layout>
      <ModalContainer
        title={"View QR Code"}
        open={viewModal}
        close={() => {
          toggleModal("view");
        }}
      >
        <Layout>
          <Layout.Section>
            <QRCode value={qr.qr} />
            <QRCode value={qr.qr} fgColor="#343434" />
            <QRCode value={qr.qr} fgColor="#2757A6" />
            <QRCode value={qr.qr} fgColor="#303F62" />
            <QRCode value={qr.qr} fgColor="#FF7008" />
          </Layout.Section>
          <Layout.Section>
            <QRCode value={qr.qr} eyeRadius={5} />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#343434" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#2757A6" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#303F62" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#FF7008" />
          </Layout.Section>
          <Layout.Section>
            <QRCode value={qr.qr} eyeRadius={5} qrStyle="dots" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#343434" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#2757A6" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#303F62" />
            <QRCode value={qr.qr} eyeRadius={5} fgColor="#FF7008" />
          </Layout.Section>
        </Layout>
      </ModalContainer>
      <ModalContainer
        title={"Create New QR Code"}
        open={createModal}
        close={() => {
          toggleModal("create");
        }}
        primaryAction={{ content: "Submit", onAction: createQRCode }}
      >
        <Form>
          <FormLayout>
            <TextField
              label="QR Code Name"
              value={newData.name}
              onChange={handleInputChange("name")}
            />
            <TextField
              label="URL"
              value={newData.url}
              onChange={handleInputChange("url")}
            />
          </FormLayout>
        </Form>
      </ModalContainer>
      <ModalContainer
        title={"Are You Sure You Want To Delete This Event?"}
        open={deleteModal}
        close={() => {
          toggleModal("delete");
        }}
        primaryAction={{ content: "Yes", onAction: deleteEvent }}
      ></ModalContainer>
    </Page>
  );
}
