import React, { useCallback } from "react";
import { DropZone } from "@shopify/polaris";

export default function FileUpload({
  setFile,
  uploading,
  accept,
  title,
  hint,
}) {
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFile, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        console.log("Rejected");
        console.log(rejectedFiles);
      } else {
        setFile(acceptedFile[0]);
      }
    },
    []
  );

  return (
    <DropZone onDrop={handleDropZoneDrop} accept={accept}>
      {uploading ? (
        <h1>Loading</h1>
      ) : (
        <DropZone.FileUpload
          actionHint={hint ? hint : "or Drop File"}
          actionTitle={title ? title : "Add File"}
        />
      )}
    </DropZone>
  );
}
