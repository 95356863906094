import React, { useCallback } from "react";
import { DropZone } from "@shopify/polaris";

export default function FileDrop({ setFile, uploading }) {
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFile, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        console.log("Rejected");
        console.log(rejectedFiles);
      } else {
        setFile(acceptedFile[0]);
      }
    },
    []
  );

  return (
    <DropZone onDrop={handleDropZoneDrop} accept="application/pdf">
      {uploading ? (
        <h1>Loading</h1>
      ) : (
        <DropZone.FileUpload actionHint="or Drop File" actionTitle="Add File" />
      )}
    </DropZone>
  );
}
