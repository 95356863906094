import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { StoreContext } from "../context/StoreContext";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Button,
  Form,
  FormLayout,
} from "@shopify/polaris";

import List from "../components/List";
import ModalContainer from "../components/ModalContainer";
import FileUpload from "../components/FileUpload";

export default function MediaKit() {
  const history = useHistory();
  const { makeRequest } = useContext(StoreContext);

  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");

  const [current, setCurrent] = useState("");

  const [products, setProducts] = useState([
    {
      id: 100,
      url: "/PDf-Versioning/341",
      heading: "ASCOT 5",
    },
    {
      id: 200,
      url: "/PDf-Versioning/256",
      heading: "ASCOT 7",
    },
    {
      id: 200,
      url: "/PDf-Versioning/256",
      heading: "sonomarc",
    },
  ]);

  const [file, setFile] = useState();

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", "testUpload");

    makeRequest("POST", "/PDF/upload", formData).then(({ data }) => {
      console.log(data);
      history.go(0);
    });
  };

  const toggleModal = (modal) => {
    switch (modal) {
      case "create":
        setCreateModal(!createModal);
        break;
      case "update":
        setUpdateModal(!updateModal);
        break;
      default:
        break;
    }
  };

  const addNewKit = () => {
    console.log("Adding");
    console.log(name);
    makeRequest("POST", "/MediaKits", {
      name,
      handle,
    }).then(({ data }) => {
      history.go(0);
    });
  };

  useEffect(() => {
    if (file) {
      console.log(file);
      uploadFile();
    }
  }, [file]);

  const deleteMediaKit = (id) => {
    console.log(id);
    makeRequest("DELETE", `/MediaKits/single/${id}`).then(({ data }) => {
      history.go(0);
    });
  };

  const updateMediaKit = (file) => {
    console.log(current);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", "testUpload");
    formData.append("mediaKit", current);
    formData.append("bucket", "mediakit");

    makeRequest("POST", `/MediaKits/single/${current}`, formData)
      .then(({ data }) => {
        console.log(data);
        alert("Success");
        // history.go(0);
      })
      .catch((err) => {
        console.log("There Was An Error");
        alert("Error Occurred");
        console.log(err);
      });
  };

  useEffect(() => {
    makeRequest("GET", "/MediaKits").then(({ data }) => {
      console.log(data);
      let productArray = data.map((x) => {
        let { name, _id } = x;
        let shortcutActions = [
          {
            content: "Delete",
            onAction: () => {
              deleteMediaKit(_id);
            },
          },
          {
            content: "Update",
            onAction: () => {
              setCurrent(_id);
              toggleModal("update");
            },
          },
        ];
        return { id: _id, heading: name, shortcutActions };
      });
      setProducts(productArray);
    });
    if (file) {
      console.log(file);
      uploadFile();
    }
  }, []);

  return (
    <Page
      title="PLEXSYS Media Kits"
      separator
      primaryAction={{
        content: "Add Media Kit",
        onAction: () => {
          toggleModal("create");
        },
      }}
    >
      <Layout>
        <Layout.Section secondary>
          <Heading>Media Kits</Heading>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <List items={products} />
          </Card>
        </Layout.Section>
      </Layout>
      <ModalContainer
        title={"Add New Media Kit"}
        open={createModal}
        close={() => {
          toggleModal("create");
        }}
      >
        <Form onSubmit={addNewKit}>
          <FormLayout>
            <TextField label="Name" value={name} onChange={setName} />
            <TextField
              label="URL Handle"
              value={handle}
              onChange={setHandle}
              helpText="Example: ascot-7"
            />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
      <ModalContainer
        title={"Update Media Kit"}
        open={updateModal}
        close={() => {
          toggleModal("update");
        }}
      >
        <FileUpload
          title="Add Zip File"
          hint="or Drop Zip File"
          accept="application/zip, application/x-zip-compressed"
          setFile={(e) => {
            updateMediaKit(e);
          }}
        />
      </ModalContainer>
    </Page>
  );
}
