import React from "react";

import { Page, Layout, Card } from "@shopify/polaris";

export default function QRCodes_Home() {
  return (
    <Page title="QR Codes" separator>
      <Layout>
        <Layout.Section oneHalf>
          <Card
            title="Employee QR Codes"
            sectioned
            primaryFooterAction={{
              content: "Manage",
              url: "/qr-codes/employee",
            }}
          >
            <p>Manage Employee Specific QR Codes</p>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card
            title="Static QR Codes"
            sectioned
            primaryFooterAction={{
              content: "Manage",
              url: "/qr-codes/static",
            }}
          >
            <p>Manage Static QR Codes for Reused Marketing Material</p>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
