import React, { useState, useEffect, useContext, useMemo } from "react";

import { useHistory } from "react-router-dom";

import moment from "moment";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Button,
  Form,
  FormLayout,
  Label,
  Select,
} from "@shopify/polaris";

import List from "../../components/List";
import ModalContainer from "../../components/ModalContainer";
import Calendar from "../../components/Calendar";

import { StoreContext } from "../../context/StoreContext";

export default function Events_Home() {
  const history = useHistory();

  const { makeRequest } = useContext(StoreContext);
  const [eventModal, setEventModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);

  const [templates, setTemplates] = useState([
    {
      _id: "3621d6g2s1df6g21sdfgdsf",
      name: "FARNBOROUGH AIR SHOW",
      description:
        "The Farnborough International Airshow remains the leader in connecting the world of aerospace, hosting some of the largest and most successful events in an ever-changing aviation industry.",
      link: "https://www.farnboroughairshow.com/",
      location: "FARNBOROUGH, UK",
    },
  ]);

  const [template, setTemplate] = useState({
    name: "",
    description: "",
    link: "",
    location: "",
  });

  const handleTemplateInputChange = (field) => {
    return (value) => {
      console.log(field, value);
      setTemplate({ ...template, [field]: value });
    };
  };

  const [name, setName] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [events, setEvents] = useState([]);

  const [pastEvents, setPastEvents] = useState([
    {
      id: 100,
      url: "/Events/341",
      heading: "2021 DSEI",
      subheading: "Sep 14, 2021 - Sep 17, 2021",
    },
  ]);

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const toggleModal = (modal) => {
    switch (modal) {
      case "event":
        setEventModal(!eventModal);
        break;
      case "template":
        setTemplateModal(!templateModal);
        break;
      default:
        break;
    }
  };

  const addNewEvent = () => {
    makeRequest("POST", "/Event", {
      name,
      startDate: start,
      endDate: end,
    }).then(({ data }) => {
      history.go(0);
    });
  };
  const addNewEventTemplate = () => {
    makeRequest("POST", "/Event", template).then(({ data }) => {
      history.go(0);
    });
  };

  useEffect(() => {
    makeRequest("GET", "/Event").then(({ data }) => {
      let today = moment(new Date()).format();
      let past = [];
      let current = [];
      let future = [];
      data.forEach((event) => {
        let { _id, startDate, endDate, name } = event;
        let momentStart = moment(startDate).format("MMM D, YYYY");
        let momentEnd = moment(endDate).format("MMM D, YYYY");
        let result = {
          _id,
          heading: name,
          subheading: `${momentStart} - ${momentEnd}`,
          url: "/Events/" + _id,
        };
        if (event.startDate > today) {
          // Event is in the future
          future.push(result);
        } else if (event.endDate < today) {
          // event is in the past
          past.push(result);
        } else {
          // event is currently running
          current.push(result);
        }
      });
      setEvents(current);
      setUpcomingEvents(future);
      setPastEvents(past);
    });
  }, []);

  let templateOptions = useMemo(() => {
    let result = templates.map((x) => {
      let { _id, name } = x;
      return { label: name, value: _id };
    });
    return result;
  }, [template]);

  return (
    <Page
      title="Event Tracking and Analytics"
      separator
      secondaryActions={[
        {
          content: "Edit Templates",
          onAction: () => {
            toggleModal("template");
          },
        },
        {
          content: "Add Event",
          onAction: () => {
            toggleModal("event");
          },
        },
      ]}
    >
      <Layout>
        {events.length > 0 && (
          <React.Fragment>
            <Layout.Section secondary>
              <Heading>Current Events</Heading>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <List items={events} />
              </Card>
            </Layout.Section>
          </React.Fragment>
        )}

        <Layout.Section secondary>
          <Heading>Upcoming Events</Heading>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <List items={upcomingEvents} />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Heading>Past Events</Heading>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <List items={pastEvents} />
          </Card>
        </Layout.Section>
      </Layout>
      <ModalContainer
        title={"Add New Event"}
        open={eventModal}
        close={() => {
          toggleModal("event");
        }}
      >
        <Form onSubmit={addNewEvent}>
          <FormLayout>
            <Select label="Event" options={templateOptions} />
            <FormLayout.Group>
              <div>
                <Label>Start Date:</Label>
                <Calendar callback={setStart} />
              </div>
              <div>
                <Label>End Date:</Label>
                <Calendar callback={setEnd} />
              </div>
            </FormLayout.Group>
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
      <ModalContainer
        title={"Create New Event Template"}
        open={templateModal}
        close={() => {
          toggleModal("template");
        }}
      >
        <Form onSubmit={addNewEventTemplate}>
          <FormLayout>
            <TextField
              label="Event Name"
              value={template.name}
              onChange={handleTemplateInputChange("name")}
            />
            <TextField
              label="Event URL"
              value={template.link}
              onChange={handleTemplateInputChange("link")}
            />
            <TextField
              label="Event Location"
              value={template.location}
              onChange={handleTemplateInputChange("location")}
            />
            <TextField
              label="Event Description"
              value={template.description}
              onChange={handleTemplateInputChange("description")}
              multiline={4}
              autoComplete="off"
            />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
    </Page>
  );
}
