import React, { useState } from "react";
import moment from "moment";

export default function Calendar({ callback }) {
  let today = moment(Date.now()).format("YYYY-MM-D");

  let [start, setStart] = useState(today);

  return (
    <div>
      <input
        type="date"
        id="start"
        name="trip-start"
        onChange={(e) => {
          setStart(e.target.value);
          if (callback) {
            callback(moment(e.target.value).format());
          }
        }}
        value={start}
        min={today}
      />
    </div>
  );
}
