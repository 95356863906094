import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import AppFrame from "./components/AppFrame";

// Base Pages
import Home from "./views/Home";
import Login from "./views/Login";

// PDF Pages
import PDFHome from "./views/PDFHome";
import PDFProduct from "./views/PDFProduct";

// Events Pages
import Events_Home from "./views/Events/Events_Home";
import Events_View from "./views/Events/Events_View";

// QR Code Pages
import QRCodes_Home from "./views/QRCodes/QRCodes_Home";
import QRCodes_Employee from "./views/QRCodes/QRCodes_Employee";
import QRCodes_Static from "./views/QRCodes/QRCodes_Static";
import QRCodes_View from "./views/QRCodes/QRCodes_View";

// Settings Page
import Settings from "./views/Settings";
import Contact_Home from "./views/Contact/Contact_Home";
import Contact_View from "./views/Contact/Contact_View";
import MediaKit from "./views/MediaKit";
import Register from "./views/Register";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/Login" component={Login} />
        <Route exact path="/register/:id" component={Register} />
        <AppFrame>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/PDf-Versioning" component={PDFHome} />
          <PrivateRoute
            exact
            path="/PDf-Versioning/:id"
            component={PDFProduct}
          />
          <PrivateRoute exact path="/Settings" component={Settings} />
          <PrivateRoute exact path="/Events" component={Events_Home} />
          <PrivateRoute exact path="/Events/:id" component={Events_View} />
          <PrivateRoute exact path="/qr-codes" component={QRCodes_Home} />
          <PrivateRoute
            exact
            path="/qr-codes/employee"
            component={QRCodes_Employee}
          />
          <PrivateRoute
            exact
            path="/qr-codes/static"
            component={QRCodes_Static}
          />
          <PrivateRoute
            exact
            path="/qr-codes/view/:id"
            component={QRCodes_View}
          />
          <PrivateRoute exact path="/contact-form" component={Contact_Home} />
          <PrivateRoute
            exact
            path="/contact-form/view/:id"
            component={Contact_View}
          />
          <PrivateRoute exact path="/mediakit" component={MediaKit} />
        </AppFrame>
      </Switch>
    </Router>
  );
}

export default App;
