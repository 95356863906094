import React from "react";
import ReactDOM from "react-dom";

import "./style.css";

import { AppProvider } from "@shopify/polaris";
import en from "@shopify/polaris/locales/en.json";

import { StoreProvider } from "./context/StoreContext";

import App from "./App";
import "@shopify/polaris/dist/styles.css";

ReactDOM.render(
  <AppProvider i18n={en}>
    <StoreProvider>
      <App />
    </StoreProvider>
  </AppProvider>,
  document.getElementById("root")
);
