import React, {
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";

import { StoreContext } from "../../context/StoreContext";

import logo from "../../PLEXSYS Logo (BLUE).png";
import { AppProvider, Frame, Navigation, TopBar } from "@shopify/polaris";

import {
  PagePlusMajor,
  ConversationMinor,
  ShopcodesMajor,
  HomeMajor,
  FolderMajor,
  TransportMajor,
} from "@shopify/polaris-icons";

export default function AppFrame({ children }) {
  const { state } = useContext(StoreContext);

  const user = useMemo(() => {
    let result = state.apiAuth.user;
    let nameArray = result.name.split(" ");
    let firstInitial = nameArray[0].substring(0, 1);
    if (nameArray.length > 1) {
      let lastInitial = nameArray[1].substring(0, 1);
      result.initials = firstInitial + lastInitial;
    } else {
      result.initials = firstInitial;
    }
    return result;
  }, [state]);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  const userMenuActions = [
    {
      items: [{ content: "Settings", url: "/settings" }],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
      name={user?.name}
      initials={user?.initials}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location={window.location.pathname}>
      <Navigation.Section
        items={[
          {
            label: "Dashboard",
            icon: HomeMajor,
            url: "/",
            exactMatch: true,
          },
          {
            label: "PDF Version Control",
            icon: PagePlusMajor,
            url: "/PDf-Versioning",
            exactMatch: true,
          },
          {
            label: "Event Tracking",
            icon: TransportMajor,
            url: "/events",
            exactMatch: true,
          },
          {
            label: "QR Codes",
            icon: ShopcodesMajor,
            url: "/qr-codes",
            exactMatch: true,
          },
          {
            label: "Contact Forms",
            icon: ConversationMinor,
            url: "/contact-form",
            exactMatch: true,
          },
          {
            label: "Media Kits",
            icon: FolderMajor,
            url: "/mediakit",
            exactMatch: true,
          },
        ]}
      />
    </Navigation>
  );

  // PLEXSYS COLORS
  let Orange = "#FF7008";
  let DarkBlue = "#303F62";
  let Blue = "#2757A6";
  let DarkGray = "#343434";
  let LightGray = "#E9E9E9";

  const theme = {
    logo: {
      width: 200,
      topBarSource: logo,
      contextualSaveBarSource: logo,
      url: "/",
      accessibilityLabel: "PLEXSYS Logo",
    },
    colors: {
      surface: "#111213",
      onSurface: "#111213",
      interactive: "#2e72d2",
      secondary: "#111213",
      primary: Orange,
      critical: "#d82c0d",
      warning: "#ffc453",
      highlight: "#5bcdda",
      success: "#008060",
      decorative: "#ffc96b",
    },
  };

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              navigationLabel: "Navigation",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
        >
          {children}
        </Frame>
      </AppProvider>
    </div>
  );
}
