import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import {
  Page,
  Layout,
  Card,
  TextField,
  Form,
  FormLayout,
} from "@shopify/polaris";

import List from "../../components/List";
import ModalContainer from "../../components/ModalContainer";

import { StoreContext } from "../../context/StoreContext";

export default function QRCodes_Employee() {
  const history = useHistory();
  const { makeRequest } = useContext(StoreContext);
  const [modal, setModal] = useState(false);

  const [newData, setNewData] = useState({});
  const [employees, setEmployees] = useState([]);

  const createQRCode = () => {
    let payload = { ...newData, qrType: "employee" };
    makeRequest("POST", "/QR", payload)
      .then(({ data }) => {
        history.go(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (field) => {
    return (value) => {
      setNewData({ ...newData, [field]: value });
    };
  };

  const toggleModal = (state) => {
    if (typeof state == "undefined") {
      setModal(!modal);
    } else {
      setModal(state);
    }
  };

  useEffect(() => {
    makeRequest("GET", "/QR/type/employee").then(({ data }) => {
      let newData = data.map((code) => {
        code.heading = code.name;
        code.subheading = `${code.url} | Visits: ${code.visits.length}`;
        code.qr = `qr.plexsys.com/visit/${code._id}`;
        code.url = `/qr-codes/view/${code._id}`;
        return code;
      });

      setEmployees(newData);
    });
  }, []);

  return (
    <Page
      title="Employee QR Codes"
      separator
      primaryAction={{
        content: "Add QR Code",
        onAction: () => {
          toggleModal();
        },
      }}
    >
      <Layout>
        <Layout.Section fullWidth>
          <Card>
            <List items={employees} />
          </Card>
        </Layout.Section>
      </Layout>
      <ModalContainer
        title={"Create New QR Code"}
        open={modal}
        close={() => {
          toggleModal();
        }}
        primaryAction={{ content: "Submit", onAction: createQRCode }}
      >
        <Form>
          <FormLayout>
            <TextField
              label="Employee Name"
              value={newData.name}
              onChange={handleInputChange("name")}
            />
            <TextField
              label="URL"
              value={newData.url}
              onChange={handleInputChange("url")}
            />
          </FormLayout>
        </Form>
      </ModalContainer>
    </Page>
  );
}
