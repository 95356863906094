import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Page,
  Layout,
  Button,
  Heading,
  Subheading,
  Link,
} from "@shopify/polaris";
import { Document, Page as DocPage, pdfjs } from "react-pdf";

import { StoreContext } from "../context/StoreContext";

import ModalContainer from "../components/ModalContainer";
import FileDrop from "../components/FileDrop";

export default function PDFProduct(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const history = useHistory();

  const { makeRequest } = useContext(StoreContext);

  const [modal, setModal] = useState(false);

  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);

  const [product, setProduct] = useState({
    name: "",
    handle: "",
    latest: { name: "" },
    pdfs: [],
  });

  const makeActive = (id) => {
    console.log(id);
    makeRequest("POST", "/PDF/latest", {
      product: props.match.params.id,
      pdf: id,
    })
      .then((res) => {
        console.log(res);
        history.go(0);
      })
      .catch((err) => {
        console.log("There Was An Error");
        console.log(err);
      });
  };

  const toggleModal = (state) => {
    if (typeof state == "undefined") {
      setModal(!modal);
    } else {
      setModal(state);
    }
  };

  useEffect(() => {
    if (props.match.params.id) {
      makeRequest("GET", `/product/single/${props.match.params.id}`).then(
        ({ data }) => {
          let { product, pdfs } = data;
          let { name, handle } = product;
          let latest = product.latest ? product.latest : "";
          let result = { name, handle, latest, pdfs };
          setProduct(result);
        }
      );
    }
  }, [props]);

  const viewPDF = (e) => {
    window.open("pdf.plexsys.com/" + product.handle);
  };

  useEffect(() => {
    if (file) {
      console.log("Uploading File!");
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", "testUpload");
      formData.append("Something", "testUpload");

      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }

      makeRequest("POST", "/PDF/upload/" + props.match.params.id, formData)
        .then((res) => {
          history.go(0);
          setUploading(false);
        })
        .catch((err) => {
          console.log("There Was An Error");
          console.log(err);
          setUploading(false);
        });
    }
  }, [file]);

  return (
    <Page
      title={product.name}
      separator
      primaryAction={{
        content: "Upload New PDF",
        onAction: () => {
          toggleModal();
        },
      }}
      secondaryActions={[
        {
          content: "View PDF",
          url: `https://pdf.plexsys.com/${product.handle}`,
          external: true,
        },
      ]}
    >
      <Layout>
        {product.pdfs.map((pdf) => {
          return (
            <React.Fragment>
              <Layout.Section oneHalf>
                <Document file={"/PDF/file/" + pdf._id}>
                  <DocPage pageNumber={1} />
                </Document>
              </Layout.Section>
              <Layout.Section oneHalf>
                <Heading>
                  {pdf.name}
                  {pdf._id === product.latest._id && " | Active"}
                </Heading>
                <Subheading>{pdf.comment}</Subheading>
                {pdf._id !== product.latest._id && (
                  <Button
                    onClick={() => {
                      makeActive(pdf._id);
                    }}
                  >
                    Set As Active
                  </Button>
                )}
              </Layout.Section>
            </React.Fragment>
          );
        })}
      </Layout>
      <ModalContainer
        title={"Upload New PDF"}
        open={modal}
        close={() => {
          toggleModal(false);
        }}
      >
        <FileDrop setFile={setFile} uploading={uploading} />
      </ModalContainer>
    </Page>
  );
}
