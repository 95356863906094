import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Button,
  Form,
  FormLayout,
  Label,
} from "@shopify/polaris";

import List from "../components/List";
import ModalContainer from "../components/ModalContainer";
import { StoreContext } from "../context/StoreContext";

export default function Settings() {
  const history = useHistory();
  const { makeRequest } = useContext(StoreContext);

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [userRegisterModal, setUserRegisterModal] = useState(false);

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerName, setRegisterName] = useState("");

  const resetPassword = (id) => {
    makeRequest("PUT", "/Auth/single/" + id).then(({ data }) => {
      history.go(0);
    });
  };

  const deleteUser = (id) => {
    makeRequest("DELETE", "/Auth/single/" + id).then(({ data }) => {
      history.go(0);
    });
  };

  const registerUser = () => {
    let name = registerName;
    let email = registerEmail;
    makeRequest("Post", "/Auth/register/", { name, email }).then(({ data }) => {
      console.log(data);
    });
  };

  const toggleModal = (modal) => {
    switch (modal) {
      case "register":
        setUserRegisterModal(!userRegisterModal);
        break;
      case "delete":
        setUserDeleteModal(!userDeleteModal);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    makeRequest("GET", "/Auth/users/").then(({ data }) => {
      let result = data.map((x) => {
        let { email, name, _id } = x;
        let shortcutActions = [
          {
            content: "Delete",
            onAction: () => {
              deleteUser(_id);
            },
          },
          {
            content: "Reset Password",
            onAction: () => {
              resetPassword(_id);
            },
          },
        ];
        return {
          subheading: email,
          heading: name,
          _id,
          shortcutActions,
        };
      });
      setUsers(result);
    });
  }, []);

  return (
    <Page
      title="Settings"
      separator
      primaryAction={{
        content: "Register New User",
        onAction: () => {
          toggleModal("register");
        },
      }}
    >
      <Layout>
        {users.length > 0 && (
          <React.Fragment>
            <Layout.Section secondary>
              <Heading>Users</Heading>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <List items={users} />
              </Card>
            </Layout.Section>
          </React.Fragment>
        )}
      </Layout>
      <ModalContainer
        title={"Register New User"}
        open={userRegisterModal}
        close={() => {
          toggleModal("create");
        }}
      >
        <Form onSubmit={registerUser}>
          <FormLayout>
            <TextField
              label="Name"
              value={registerName}
              onChange={setRegisterName}
            />
            <TextField
              label="Email"
              value={registerEmail}
              onChange={setRegisterEmail}
            />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
    </Page>
  );
}
