import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import moment from "moment";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Button,
  Form,
  FormLayout,
  Label,
} from "@shopify/polaris";

import List from "../../components/List";
import ModalContainer from "../../components/ModalContainer";
import Calendar from "../../components/Calendar";

import { StoreContext } from "../../context/StoreContext";

export default function Contact_Home() {
  const history = useHistory();

  const { makeRequest } = useContext(StoreContext);
  const [modal, setModal] = useState(false);

  const [name, setName] = useState("");

  const [forms, setForms] = useState([]);

  const toggleModal = (state) => {
    if (typeof state == "undefined") {
      setModal(!modal);
    } else {
      setModal(state);
    }
  };

  const addNewForm = () => {
    makeRequest("POST", "/Contact", {
      name,
    }).then(({ data }) => {
      history.go(0);
    });
  };

  useEffect(() => {
    makeRequest("GET", "/Contact").then(({ data }) => {
      let result = data.map((x) => {
        return { heading: x.name, url: "/contact-form/view/" + x._id };
      });
      setForms(result);
    });
  }, []);

  return (
    <Page
      title="Contact Forms"
      separator
      primaryAction={{
        content: "Add Form",
        onAction: () => {
          toggleModal();
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <List items={forms} />
          </Card>
        </Layout.Section>
      </Layout>
      <ModalContainer
        title={"Add New Form"}
        open={modal}
        close={() => {
          toggleModal(false);
        }}
      >
        <Form onSubmit={addNewForm}>
          <FormLayout>
            <TextField label="Form Name" value={name} onChange={setName} />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
    </Page>
  );
}
