import React, { useState } from "react";
import "./style.css";
import { QRCode } from "react-qrcode-logo";

import {
  Page,
  Layout,
  Card,
  TextField,
  Form,
  FormLayout,
  Select,
} from "@shopify/polaris";

export default function QRCodes_View(props) {
  const [background, setBackground] = useState("transparent");
  const [foreground, setForeground] = useState("white");
  const [width, setWidth] = useState(500);

  const colorOptions = [
    { label: "White", value: "white" },
    { label: "Black", value: "black" },
    { label: "Transparent", value: "transparent" },
    { label: "PLEXSYS Dark Gray", value: "#343434" },
    { label: "PLEXSYS Blue", value: "#2757A6" },
    { label: "PLEXSYS Dark Blue / Gray", value: "#303F62" },
    { label: "PLEXSYS Orange", value: "#FF7008" },
    { label: "PLEXSYS Light Gray", value: "#E9E9E9" },
  ];

  return (
    <Page title="View QR Code" separator>
      <Layout>
        <Layout.Section>
          <Select
            label="Background Color"
            options={colorOptions}
            value={background}
            onChange={setBackground}
          />
          <Select
            label="Foreground Color"
            options={colorOptions}
            value={foreground}
            onChange={setForeground}
          />
          <TextField
            label="Size"
            value={String(width)}
            onChange={(e) => {
              setWidth(Number(e));
            }}
          />
        </Layout.Section>
        <Layout.Section>
          <QRCode
            value={"https://www.plexsys.com/mk/vadaar"}
            bgColor={background}
            fgColor={foreground}
            style={{ width: "500px", height: "500px" }}
            size={width}
          />
          <QRCode
            value={"qr.plexsys.com/" + props.match.params.id}
            bgColor={background}
            fgColor={foreground}
            style={{ width: "500px", height: "500px" }}
            size={width}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
