const initialState = {
  apiAuth: {
    user: {
      name: "",
      email: "",
    },
    token: "",
    exp: 0,
  },
};

// Set State as Local Storage Item for Persistant State
let localState;
if (localStorage.getItem("state")) {
  localState = localStorage.getItem("state");
} else {
  localStorage.setItem("state", JSON.stringify(initialState));
  localState = JSON.stringify(initialState);
}

const types = {
  SET_API: "SET_API",
};

const reducer = (state = localState, action) => {
  let updatedState;
  switch (action.type) {
    case types.SET_API:
      updatedState = { ...state, apiAuth: action.payload };
      localStorage.setItem("state", JSON.stringify(updatedState));
      return updatedState;
    default:
      throw new Error("Unexpected action");
  }
};
let globalState = JSON.parse(localState);
export { globalState, types, reducer };
