import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Page,
  Layout,
  Card,
  Heading,
  TextField,
  Button,
  Form,
  FormLayout,
} from "@shopify/polaris";

import { StoreContext } from "../context/StoreContext";

export default function Register(props) {
  const history = useHistory();
  const { makeRequest } = useContext(StoreContext);

  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");

  const submitPassword = () => {
    console.log(passwordOne.length);
    if (passwordOne.length < 7) {
      return alert("Password Must Be Atleast 7 Characters Long");
    }
    if (passwordOne !== passwordTwo) {
      alert("Passwords Don't Match");
    } else {
      makeRequest("POST", "/Auth/Register/" + props.match.params.id, {
        password: passwordOne,
      }).then(({ data }) => {
        console.log(data);

        history.push("/login");
      });
    }
  };

  return (
    <Page title="Register User" separator>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Form onSubmit={submitPassword}>
              <FormLayout>
                <TextField
                  label="Password"
                  value={passwordOne}
                  onChange={setPasswordOne}
                  type="password"
                />
                <TextField
                  label="Confirm Password"
                  value={passwordTwo}
                  onChange={setPasswordTwo}
                  type="password"
                />
                <Button submit>Submit</Button>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
