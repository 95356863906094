import React, { useState, useEffect, useMemo, useContext } from "react";

import { useHistory } from "react-router-dom";

import { StoreContext } from "../../context/StoreContext";

import {
  Page,
  Layout,
  Card,
  DataTable,
  Form,
  FormLayout,
  Label,
  Heading,
  Select,
  TextField,
  Button,
} from "@shopify/polaris";

import ModalContainer from "../../components/ModalContainer";

export default function Contact_View(props) {
  const history = useHistory();

  const { makeRequest } = useContext(StoreContext);

  const [form, setForm] = useState({});
  const [headings, setHeadings] = useState([]);
  const [submissions, setSubmissions] = useState([]);

  const [formModal, setFormModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteForm = (e, i) => {
    makeRequest("DELETE", "/contact/single/" + props.match.params.id)
      .then(({ data }) => {
        history.push("/Forms");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addFormField = () => {
    let updatedForm = { ...form };
    updatedForm.inputs.push({ type: "text", label: "", name: "", options: [] });
    setForm(updatedForm);
  };

  const addFormNotification = () => {
    let updatedForm = { ...form };
    updatedForm.notifications.push({ email: "", includeResponse: true });
    setForm(updatedForm);
  };

  const editFormFields = (index, key, value) => {
    let updatedForm = { ...form };
    updatedForm.inputs[index] = { ...updatedForm.inputs[index], [key]: value };
    setForm(updatedForm);
  };

  const editFormNotification = (index, key, value) => {
    let updatedForm = { ...form };
    updatedForm.notifications[index] = {
      ...updatedForm.notifications[index],
      [key]: value,
    };
    setForm(updatedForm);
  };

  const removeFormNotification = (index) => {
    let formNotifications = form.notifications.filter((x, i) => {
      return i !== index;
    });
    let updatedForm = { ...form, notifications: formNotifications };
    setForm(updatedForm);
  };

  const toggleModal = (modal) => {
    switch (modal) {
      case "form":
        setFormModal(!formModal);
        break;
      case "edit":
        setEditModal(!editModal);
        break;
      case "delete":
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };
  const submitForm = () => {
    makeRequest("PUT", "/contact/single/" + props.match.params.id, form).then(
      ({ data }) => {
        history.go(0);
      }
    );
  };

  useEffect(() => {
    makeRequest("GET", "/contact/single/" + props.match.params.id).then(
      ({ data }) => {
        console.log(data);
        let { form, responses } = data;
        let formHeadings = form.inputs.map((x) => {
          return x.label;
        });
        let formNames = form.inputs.map((x) => {
          return x.name;
        });

        let formResponses = responses.map(({ values }) => {
          let result = [];
          formNames.forEach((y) => {
            result.push(values[y]);
          });
          return result;
        });
        setHeadings(formHeadings);
        setSubmissions(formResponses);
        setForm(form);
      }
    );
  }, []);

  const options = [
    { label: "Text", value: "text" },
    { label: "Text Area", value: "textarea" },
    { label: "Select", value: "select" },
    { label: "Checkbox", value: "checkbox" },
    { label: "Multi Bheckbox", value: "multicheckbox" },
  ];

  return (
    <Page
      title={form?.name}
      separator
      primaryAction={{
        content: "Edit Fields",
        onAction: () => {
          toggleModal("edit");
        },
      }}
      secondaryActions={[
        {
          content: "Delete Form",
          onAction: () => toggleModal("delete"),
        },
        {
          content: "Edit Form",
          onAction: () => toggleModal("form"),
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <DataTable
              columnContentTypes={"text"}
              headings={headings}
              rows={submissions}
            />
          </Card>
        </Layout.Section>
      </Layout>

      <ModalContainer
        title={"Are You Sure You Want To Delete This Form?"}
        open={deleteModal}
        close={() => {
          toggleModal("delete");
        }}
        primaryAction={{ content: "Yes", onAction: deleteForm }}
      ></ModalContainer>

      <ModalContainer
        title={"Edit Form Fields"}
        open={editModal}
        close={() => {
          toggleModal("edit");
        }}
        primaryAction={{ content: "Submit", onAction: submitForm }}
      >
        <Form onSubmit={editFormFields}>
          <FormLayout>
            {form?.inputs?.map((x, i) => {
              return (
                <>
                  <h1>Input {i + 1}</h1>
                  <TextField
                    label="Label"
                    value={x.label}
                    onChange={(text) => {
                      editFormFields(i, "label", text);
                    }}
                  />
                  <TextField
                    label="Name"
                    value={x.name}
                    onChange={(text) => {
                      editFormFields(i, "name", text);
                    }}
                  />
                  <Select
                    label="Field Type"
                    options={options}
                    value={x.type}
                    onChange={(text) => {
                      editFormFields(i, "type", text);
                    }}
                  />
                </>
              );
            })}
            <Button onClick={addFormField}>Add New Field</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
      <ModalContainer
        title={"Edit Form"}
        open={formModal}
        close={() => {
          toggleModal("form");
        }}
        primaryAction={{ content: "Submit", onAction: submitForm }}
      >
        <Form>
          <FormLayout>
            <TextField
              label="Form Name"
              value={form.name}
              onChange={(text) => {
                setForm({ ...form, name: text });
              }}
            />
            <Heading>Email Notifications</Heading>
            {form?.notifications?.map((x, i) => {
              return (
                <FormLayout.Group condensed>
                  <TextField
                    value={x.email}
                    onChange={(text) => {
                      editFormNotification(i, "email", text);
                    }}
                  />
                  <Button
                    onClick={() => {
                      removeFormNotification(i);
                    }}
                  >
                    Remove
                  </Button>
                </FormLayout.Group>
              );
            })}
            <Button onClick={addFormNotification}>Add New Email</Button>
          </FormLayout>
        </Form>
      </ModalContainer>
    </Page>
  );
}
